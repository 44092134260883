import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
// import { useTable } from 'react-table'

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";

// Imports React bootstrap
import { Table } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

// File Imports
import ComboBox from "Features/ComboBox";
import {globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";

function FrmMBOMRMFGSRFilter(props) {
  const configConstants = ConfigConstants();
  const { COMPANY_ID } = configConstants;

  // use refs
  const comboBox = useRef();

  // Table Data
  const [data, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  let localStorageKeyArr = [];

  // Get Checked data
  let checkedData = [];

  // Filter Master Combo
  const [cmbMasterOptions, setCmbMasterOptions] = useState([]);

  // Select box
  var expanded = false;

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable({
  //   columns,
  //   data
  // })

  useEffect(() => {
    const functionCall = async () => {
      await createFilterQuery();
      await FnFillCombos();
    }

    functionCall()

    $(document).mouseup(function (e) {
      var container = $("#erp-checkboxes");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    });

  }, [])

  const FnFillCombos = async () => {
    // Fill filter Master Combo
    resetGlobalQuery();
    globalQuery.columns.push("property_name");
    globalQuery.columns.push("property_value");

    globalQuery.conditions.push({
      field: "company_id",
      operator: "=",
      value: COMPANY_ID
    });
    globalQuery.conditions.push({
      field: "properties_master_name",
      operator: "=",
      value: "RMFilterMasters"
    });
    globalQuery.table = "amv_properties";
    debugger
    const apiCall = await comboBox.current.fillFiltersCombo(globalQuery);
    setCmbMasterOptions(apiCall)

    // Category display
    const catCount = sessionStorage.getItem('compRMCatCount')
    const cmbMasterElements = document.getElementById('cmb_master_name');
    var counter = 1;

    for (let cmbMasterEleInd = 0; cmbMasterEleInd < cmbMasterElements.length; cmbMasterEleInd++) {
      if (cmbMasterElements[cmbMasterEleInd].value === 'smv_product_category' + counter + '') {
        if (counter > parseInt(catCount)) {
          cmbMasterElements[cmbMasterEleInd].style.display = 'none'
          counter++;
        } else {
          counter++;
          cmbMasterElements[cmbMasterEleInd].style.display = 'block'
        }
      }
    }
  }

  const createFilterQuery = async () => {
    var checkedList = [];
    var checkListName = [];

    resetGlobalQuery();
    globalQuery.columns.push("*");
    globalQuery.conditions.push({
      field: "company_id",
      operator: "=",
      value: COMPANY_ID
    });
    globalQuery.table = "smv_product_rm_fg_sr"

    $("input:checkbox[name=filterCheckBox]:checked").each(function () {
      const checkbValue = $(this).val()
      if (parseInt(checkbValue) !== 0) {
        checkedList.push(checkbValue);
        checkListName.push($(this).attr('fieldName'));
      }
    });

    localStorage.setItem($('#cmb_master_name').val(), JSON.stringify(checkedList))
    localStorage.setItem($('#cmb_master_name').val() + "Name", JSON.stringify(checkListName))

    localStorageKeyArr.push($('#cmb_master_name').val())
    localStorageKeyArr.push($('#cmb_master_name').val() + "Name")
    localStorage.setItem(`localStorageKeyArr`, JSON.stringify(localStorageKeyArr))


    var query = ""
    var productTypes = JSON.parse(localStorage.getItem('smv_product_type'));
    var productTypesName = JSON.parse(localStorage.getItem('smv_product_typeName'))
    if (localStorage.getItem('smv_product_type') !== null && localStorage.getItem('smv_product_type') !== '') {
      if (productTypes.length !== 0) {
        var productTPStr = [];
        var productTPNameStr = '';
        for (let indexPT = 0; indexPT < productTypes.length; indexPT++) {
          productTPStr.push(productTypes[indexPT])
          productTPNameStr += JSON.stringify(productTypesName[indexPT]) + ', '
        }
        // productTPStr = productTPStr.substring(0, productTPStr.length - 2)
        productTPNameStr = productTPNameStr.substring(0, productTPNameStr.length - 2)
        globalQuery.conditions.push({ field: "product_type_id", operator: "IN", values: productTPStr });

        query += "product_type_id IN" + "(" + productTPNameStr + ")\n"
      }
    }
    for (let catIndex = 1; catIndex <= 5; catIndex++) {
      var category = JSON.parse(localStorage.getItem('smv_product_category' + catIndex))
      var categoryName = JSON.parse(localStorage.getItem('smv_product_category' + catIndex + 'Name'))
      if (localStorage.getItem('smv_product_category' + catIndex) !== null && localStorage.getItem('smv_product_category' + catIndex) !== '') {
        if (category.length !== 0) {
          var catStr = [];
          var catStrName = '';
          for (let indexCat = 0; indexCat < category.length; indexCat++) {
            catStr.push(category[indexCat])
            catStrName += JSON.stringify(categoryName[indexCat]) + ', '
          }
          catStrName = catStrName.substring(0, catStrName.length - 2)

          globalQuery.conditions.push({ field: 'product_category' + catIndex + '_id', operator: "IN", values: catStr });
          query += 'product_category' + catIndex + '_id IN' + "(" + catStrName + ")\n"
        }

      }
    }

    let productMake = JSON.parse(localStorage.getItem('smv_product_make'))
    let productMakeName = JSON.parse(localStorage.getItem('smv_product_makeName'))
    if (localStorage.getItem('smv_product_make') !== null && localStorage.getItem('smv_product_make') !== '') {
      if (productMake.length !== 0) {
        let productMakeStr = [];
        let productMakeNameStr = '';
        for (let indexSh = 0; indexSh < productMake.length; indexSh++) {
          productMakeStr.push(productMake[indexSh])
          productMakeNameStr += JSON.stringify(productMakeName[indexSh]) + ', '
        }
        productMakeNameStr = productMakeNameStr.substring(0, productMakeNameStr.length - 2)
        globalQuery.conditions.push({ field: "product_make_id", operator: "IN", values: productMakeStr });
        query += "product_make_id IN" + "(" + productMakeNameStr + ")\n"
      }
    }


    var materialType = JSON.parse(localStorage.getItem('smv_product_material_type'))
    var materialTypeName = JSON.parse(localStorage.getItem('smv_product_material_typeName'))
    if (localStorage.getItem('smv_product_material_type') !== null && localStorage.getItem('smv_product_material_type') !== '') {
      if (materialType.length !== 0) {
        var materialTPStr = [];
        var materialTpNameStr = '';
        for (let indexMt = 0; indexMt < materialType.length; indexMt++) {
          materialTPStr.push(materialType[indexMt])
          materialTpNameStr += JSON.stringify(materialTypeName[indexMt]) + ', '
        }
        materialTpNameStr = materialTpNameStr.substring(0, materialTpNameStr.length - 2)

        globalQuery.conditions.push({ field: "product_material_type_id", operator: "IN", values: materialTPStr });
        query += "product_material_type_id IN" + "(" + materialTpNameStr + ")\n"
      }
    }

    let materialGrade = JSON.parse(localStorage.getItem('smv_product_material_grade'))
    let materialGradeName = JSON.parse(localStorage.getItem('smv_product_material_gradeName'))
    if (localStorage.getItem('smv_product_material_grade') !== null && localStorage.getItem('smv_product_material_grade') !== '') {
      if (materialGrade.length !== 0) {
        let materialGRStr = [];
        let materialGRNameStr = '';
        for (let indexMG = 0; indexMG < materialGrade.length; indexMG++) {
          materialGRStr.push(materialGrade[indexMG])
          materialGRNameStr += JSON.stringify(materialGradeName[indexMG]) + ', '
        }
        materialGRNameStr = materialGRNameStr.substring(0, materialGRNameStr.length - 2)
        globalQuery.conditions.push({ field: "product_fg_material_grade_id", operator: "IN", values: materialGRStr });

        query += "product_fg_material_grade_id IN" + "(" + materialGRNameStr + ")\n"
      }
    }

    var materialShape = JSON.parse(localStorage.getItem('smv_product_material_shape'))
    var materialShapeName = JSON.parse(localStorage.getItem('smv_product_material_shapeName'))
    if (localStorage.getItem('smv_product_material_shape') !== null && localStorage.getItem('smv_product_material_shape') !== '') {
      if (materialShape.length !== 0) {
        var materialShStr = [];
        var materialShNameStr = '';
        for (let indexSh = 0; indexSh < materialShape.length; indexSh++) {
          materialShStr.push(materialShape[indexSh])
          materialShNameStr += JSON.stringify(materialShapeName[indexSh]) + ', '
        }
        materialShNameStr = materialShNameStr.substring(0, materialShNameStr.length - 2)
        globalQuery.conditions.push({ field: "product_material_shape_id", operator: "IN", values: materialShStr });
        query += "product_material_shape_id IN" + "(" + materialShNameStr + ")\n"
      }
    }

    $('#selctedFilters').text(query)

    // APi Call
    if (query !== "") {
      console.log(globalQuery);
      const responceOfFilteredData = await comboBox.current.fillFiltersCombo(globalQuery);

      console.log("responceOfFilteredData: ", responceOfFilteredData)
      if (responceOfFilteredData.length > 0) {
        $('#bomNoRcrdId').hide();
        $('.bomFilterData').show();
        var column = [];
        var columnHeads = [];
        for (let keys in responceOfFilteredData[0]) { columnHeads.push(keys) };
        console.log("column heads: ", columnHeads)
        for (let colKey = 0; colKey < columnHeads.length; colKey++) {
          if (colKey === 0) {
            column.push({
              Headers: "Action",
              accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <input type='checkbox' class="option" name="checkFilteredData" id={'checkFilteredDataId_' + row.row.original.product_rm_id}
                    value={row.row.original.product_rm_id} onClick={() => { handleCheckbox(row.row.original.product_rm_id); }}>
                  </input>
                </div>
              ),
            });
          }
          if (!columnHeads[colKey].includes('company_name') && !columnHeads[colKey].includes('company_branch_name') && !columnHeads[colKey].includes('_id') && !columnHeads[colKey].includes('is_') && !columnHeads[colKey].includes('field_name')) {
            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
          }
        }
        setColumns(column)
        setFilteredData(responceOfFilteredData)
        if (sessionStorage.getItem('isComboFilterExist') === 'true') {
          sessionStorage.setItem(`comboFilterData`, JSON.stringify(responceOfFilteredData))
        }
        await checkIfBomFilterDataIsExist();

      } else {
        $('.bomFilterData').hide();
        $('#bomNoRcrdId').show();
      }
    } else {
      $('.bomFilterData').hide();
      $('#bomNoRcrdId').show();
    }
  }

  const handleCheckbox = (product_rm_id) => {
    $('input:checkbox[name="checkFilteredData"][value="' + product_rm_id + '"]').attr('checked', false);
  }

  const checkIfBomFilterDataIsExist = async () => {
    const getDataIfExist = JSON.parse(sessionStorage.getItem('bomfilteredData'))
    if (getDataIfExist !== null && getDataIfExist !== '') {
      const valuesToCheck = getDataIfExist.map(item => item.product_rm_id.toString());
      $('input:checkbox[name="checkFilteredData"]').each(function () {
        if (valuesToCheck.includes($(this).val())) {
          $(this).prop('checked', true);
        }
      });
    }

  }
  const showCheckboxes = () => {
    var checkboxes = document.getElementById("erp-checkboxes");
    const items = document.querySelectorAll(".item");

    if (!expanded) {
      if (items.length !== 0) {
        checkboxes.style.display = "block";
        expanded = true;
      }
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }

  }

  const masterOnChange = async () => {
    var IsProductTypePresent = sessionStorage.getItem('smv_product_type');
    var IsCategory1Present = sessionStorage.getItem('smv_product_category1');
    var IsCategory2Present = sessionStorage.getItem('smv_product_category2');
    var IsCategory3Present = sessionStorage.getItem('smv_product_category3');
    var IsCategory4Present = sessionStorage.getItem('smv_product_category4');
    var IsCategory5Present = sessionStorage.getItem('smv_product_category5');
    let IsMaterialMakePresent = sessionStorage.getItem('smv_product_make');
    var IsMaterialTypePresent = sessionStorage.getItem('smv_product_material_type');
    var IsMaterialGradePresent = sessionStorage.getItem('smv_product_material_grade');
    var IsMaterialShapePresent = sessionStorage.getItem('smv_product_material_shape');

    $('#erp-checkboxes').empty();
    var masterValue = $('#cmb_master_name').val();

    switch (masterValue) {
      case 'smv_product_type':
        const filterList = await createWhereJson(masterValue, "productTpDependent")
        checkedSelectedFilterFields(IsProductTypePresent, filterList)
        break;
      case 'smv_product_category1':
        const filterCat1List = await createWhereJson(masterValue, 1)
        checkedSelectedFilterFields(IsCategory1Present, filterCat1List)
        break;
      case 'smv_product_category2':
        const filterCat2List = await createWhereJson(masterValue, 2)
        checkedSelectedFilterFields(IsCategory2Present, filterCat2List)
        break;
      case 'smv_product_category3':
        const filterCat3List = await createWhereJson(masterValue, 3)
        checkedSelectedFilterFields(IsCategory3Present, filterCat3List)
        break;
      case 'smv_product_category4':
        const filterCat4List = await createWhereJson(masterValue, 4)
        checkedSelectedFilterFields(IsCategory4Present, filterCat4List)
        break;
      case 'smv_product_category5':
        const filterCat5List = await createWhereJson(masterValue, 5)
        checkedSelectedFilterFields(IsCategory5Present, filterCat5List)
        break;
      case 'smv_product_make':
        const filterProductMakeList = await createWhereJson(masterValue, "productTpDependent")
        checkedSelectedFilterFields(IsMaterialMakePresent, filterProductMakeList)
        break;
      case 'smv_product_material_type':
        const filtermaterialTypeList = await createWhereJson(masterValue, "productTpDependent")
        checkedSelectedFilterFields(IsMaterialTypePresent, filtermaterialTypeList)
        break;
      case 'smv_product_material_grade':
        const filtermaterialGradeList = await createWhereJson(masterValue, "productTpDependent")
        checkedSelectedFilterFields(IsMaterialGradePresent, filtermaterialGradeList)
        break;
      case 'smv_product_material_shape':
        const filtermaterialShapeList = await createWhereJson(masterValue, "productTpDependent")
        checkedSelectedFilterFields(IsMaterialShapePresent, filtermaterialShapeList)
        break;
      default:
        break;

    }

  }

  const createWhereJson = async (masterValue, count) => {
    debugger
    var IsProductTypePresent = JSON.parse(sessionStorage.getItem('smv_product_type'));

    resetGlobalQuery();
    globalQuery.columns.push("field_id");
    globalQuery.columns.push("field_name");
    globalQuery.conditions.push({
      field: "company_id",
      operator: "=",
      value: COMPANY_ID
    });
    globalQuery.table = masterValue


    var createJson = { 'viewOrTable': {}, 'selectQuery': {}, 'whereQuery': {} }
    createJson['viewOrTable'][masterValue] = masterValue
    createJson['selectQuery']["field_id, field_name"] = "field_id, field_name"
    if (count !== 'productTpDependent') {
      for (let countIndex = 1; countIndex <= count; countIndex++) {
        if (countIndex === 1) {
          if (IsProductTypePresent !== '' && IsProductTypePresent !== null && IsProductTypePresent.length !== 0) {
            globalQuery.conditions.push({ field: "product_type_id", operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_type')) });
          }
        }
        if (sessionStorage.getItem('smv_product_category' + countIndex) !== null && sessionStorage.getItem('smv_product_category' + countIndex) !== '') {
          globalQuery.conditions.push({ field: 'product_category' + countIndex + '_id', operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_category' + countIndex)) });
        }
      }
    } else {
      if (masterValue !== 'smv_product_type') {
        if (IsProductTypePresent !== '' && IsProductTypePresent !== null && IsProductTypePresent.length !== 0) {
          globalQuery.conditions.push({ field: "product_type_id", operator: "IN", values: JSON.parse(sessionStorage.getItem('smv_product_type')) });
        }
      } else if (masterValue === 'smv_product_type') {
        globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ['RM', 'GN', 'TM'] });
      }
    }

    // APi Call
    var data = await comboBox.current.fillFiltersCombo(globalQuery)

    $('.list-items').empty();

    for (let filtrListId = 0; filtrListId < data.length; filtrListId++) {
      if (filtrListId === 0) {
        $('.list-items').append('<li class="item"><span class="checkbox"><input type="checkbox" id="All" fieldName=""  name="filterCheckBox" value=' + filtrListId + ' class="erp_radio_button filterCheckBox"  /></span> <span class="item-text">All</span> </li>')
      }
      $('.list-items').append('<li class="item"><span class="checkbox"><input type="checkbox" fieldName="' + data[filtrListId].field_name + '"  name="filterCheckBox" value=' + data[filtrListId].field_id + ' id="filterCheck-' + data[filtrListId].field_id + '" class="erp_radio_button filterCheckBox"  /></span> <span class="item-text">' + data[filtrListId].field_name + '</span> </li>')
    }
    $('.filterCheckBox').click(async function () {
      const checkboxVal = $(this).val();
      if (parseInt(checkboxVal) === 0) {
        if ($("#All").is(":checked") === false) {
          await checkAllFieldsFrFiltrList('uncheck');
        } else {
          await checkAllFieldsFrFiltrList('check');
        }
      }
      var checkedList = [];
      $("input:checkbox[name=filterCheckBox]:checked").each(async function () {
        const value = $(this).val()
        if (parseInt(value) !== 0) {
          checkedList.push(value);
        }
      });
      sessionStorage.setItem(masterValue, JSON.stringify(checkedList))
      await createFilterQuery();
    });

    return data;

  }

  const checkAllFieldsFrFiltrList = async (key) => {
    const fliterCheckbox = document.getElementsByName('filterCheckBox');
    if (key === 'check') {
      for (var checkbox of fliterCheckbox) {
        $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('checked', true);
        if (parseInt(checkbox.value) !== 0)
          $('input:checkbox[name="filterCheckBox"][value="' + checkbox.value + '"]').attr('disabled', true);
      }
    } else {
      for (var uncheckCheckbox of fliterCheckbox) {
        $('input:checkbox[name="filterCheckBox"][value="' + uncheckCheckbox.value + '"]').attr('checked', false);
        $('input:checkbox[name="filterCheckBox"][value="' + uncheckCheckbox.value + '"]').attr('disabled', false);

      }
    }

  }
  const checkedSelectedFilterFields = async (sesssionVal, filterList) => {
    if (sesssionVal !== '' && sesssionVal !== null) {
      var fliterCheckbox = document.getElementsByName('filterCheckBox');
      let IsSessionvalPresentParse = JSON.parse(sesssionVal)
      for (let index = 0; index < filterList.length; index++) {
        for (var checkbox of fliterCheckbox) {
          if (parseInt(checkbox.value) === filterList[index].field_id) {
            $('input:checkbox[name="filterCheckBox"][value="' + IsSessionvalPresentParse[index] + '"]').attr('checked', true);
          }
        }
      }
    }

  }
  const applyFilterNShowData = () => {
    let availObj;
    const requiredCols = JSON.parse(localStorage.getItem('requiredCols'));
    const checked = $("input:checkbox[name=checkFilteredData]:checked")
    for (let index = 0; index < checked.length; index++) {
      const element = parseInt(checked[index].value);

      let availableBomData = JSON.parse(sessionStorage.getItem('bomfilteredData'));
      if (availableBomData !== null && availableBomData !== "") {
        availObj = availableBomData.find(item => item.product_rm_id === element);
      }


      if (availObj === undefined) {
        for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
          const tdData = data[dataIndex];
          if (tdData.product_rm_id === element) {
            const filteredDataRecord = {};
            requiredCols.forEach((col) => {
              if (tdData.hasOwnProperty(col)) {
                filteredDataRecord[col] = tdData[col];
              } else {
                filteredDataRecord[col] = 0;
              }
            });
            filteredDataRecord['product_rm_quantity'] = 1;
            checkedData.push(filteredDataRecord);
          }

        }
      } else {
        checkedData.push(availObj)
      }


    }
    console.log("applyFilterNShowData", checkedData)
    sessionStorage.setItem('bomfilteredData', JSON.stringify(checkedData))
    props.closeModal();
  }


  function toggleOptionsChkBoxes(key) {
    switch (key) {
      case "checkAll":
        $('.option').prop('checked', $('#chkAllOptions').is(":checked"))
        break;
      default:
        break;
    }
  }

  $('.option').change(function () {
    $('#chkAllOptions').prop('checked', $('input:checkbox.option:checked').length === $('input:checkbox.option').length);
  });

  return (
    <> <ComboBox ref={comboBox} />
      <div className="card filter">
        <div className='row'>
          <Form.Label className="erp-form-label-md">Filters:</Form.Label>
        </div>
        <div className='row'>
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-2'>
                <Form.Label className="erp-form-label">Masters:</Form.Label>
              </div>
              <div className='col'>
                <select id="cmb_master_name" className="form-select form-select-sm" onChange={() => masterOnChange()}>
                  <option value=""></option>
                  {cmbMasterOptions?.map((masterVal) => (
                    <option value={masterVal.property_name}>{masterVal.property_value}</option>

                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-2'>
                <Form.Label className="erp-form-label">Filters:</Form.Label>
              </div>
              <div className='col'>
                <div className="erp-container">
                  <div className="select-btn" onClick={() => showCheckboxes()}>
                    <span className="form-select form-select-sm">Select</span>
                  </div>
                  <ul className="list-items" id="erp-checkboxes">

                  </ul>
                </div>

              </div>
            </div>
          </div>
          <div className='col-sm-4 erp_form_col_div'>
            <div className='row'>
              <div className='col-sm-2'>
                <Form.Label className="erp-form-label">Selection Criteria:</Form.Label>
              </div>
              <div className='col'>
                <Form.Control as="textarea" id="selctedFilters" rows={1} className="erp_txt_area" readOnly />

              </div>
            </div>


          </div>

        </div>
      </div>
      <div className="row">
        <span id="bomNoRcrdId" className="no-recrds erp-form-label-md" style={{ display: "none" }}>No records found...</span>
        {
          columns.length > 0 ?
            <span>
              <input type='checkbox' id="chkAllOptions" onClick={(e) => toggleOptionsChkBoxes('checkAll')} /> <label class="erp-form-label pb-1"> Select All Options</label>
            </span>
            : ""
        }
        <div className="bomFilterData" style={{ display: 'none' }}>
          {/* Commented due to react-table 6.11.5 */}
          {/* <Table className="erp_table erp_table_scroll" bordered {...getTableProps()} >
            <thead className="erp_table_head">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th className="erp_table_th" {...column.getHeaderProps()}>{column.render('Headers')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className="erp_table_td" {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                    })}
                  </tr>
                )
              })}
            </tbody>

          </Table> */}
        </div>
      </div>
      <div className="erp_frm_Btns bomFilterData" style={{ display: 'none' }}>
        <MDButton className="erp-gb-button erp_MLeft_btn" variant="button" onClick={applyFilterNShowData} fontWeight="regular">
          Add
        </MDButton>
      </div>
    </>
  )
}

export default FrmMBOMRMFGSRFilter